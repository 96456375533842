import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/layouts/Chooser.tsx";
import '../styles/index.scss';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Section = makeShortcode("Section");
const Container = makeShortcode("Container");
const Heading = makeShortcode("Heading");
const ProjectsSection = makeShortcode("ProjectsSection");
const Columns = makeShortcode("Columns");
const Column = makeShortcode("Column");
const Content = makeShortcode("Content");
const Button = makeShortcode("Button");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Section id='map' size='large' style={{
      background: 'url(/carto-projet.jpg) no-repeat center center',
      backgroundSize: 'cover'
    }} mdxType="Section">
  <Container mdxType="Container">
    <Heading renderAs='h2' size={2} align='center' textColor='white' mdxType="Heading">
      Portefeuille des projets d'Enia
    </Heading>
  </Container>
    </Section>
    <Section size='small' backgroundColor='light' mdxType="Section">
  <Container breakpoint='fluid' mdxType="Container">
    <ProjectsSection mdxType="ProjectsSection" />
  </Container>
    </Section>
    <Section id='actionzone' size='medium' textAlign='center' mdxType="Section">
  <Container textSize='5' mdxType="Container">
    <Heading renderAs='h2' size={2} align='center' mdxType="Heading">
      Périmètre d'<strong className='highlighted'>intervention</strong>
    </Heading>
    <br />
    <Columns breakpoint='desktop' mdxType="Columns">
      <Column mdxType="Column">
        <div className="timeline" style={{
              textAlign: 'left'
            }}>
          {
                /* <header class="timeline-header">
                 <span class="tag is-primary">Début du projet</span>
                </header> */
              }
          <div className="timeline-item">
            <div className="timeline-marker is-icon">1</div>
            <div className="timeline-content">
              <p>Sécurisation de votre plan d'approvisionnement</p>
            </div>
          </div>
          <div className="timeline-item">
            <div className="timeline-marker is-icon">2</div>
            <div className="timeline-content">
              <p>Communication et rencontre des acteurs locaux</p>
              <p>(collectivités, populations, fournisseurs…)</p>
            </div>
          </div>
          <div className="timeline-item">
            <div className="timeline-marker is-icon">3</div>
            <div className="timeline-content">
              <p>Identification du foncier</p>
            </div>
          </div>
          <header className="timeline-header">
            <span className="tag">Foncier sécurisé</span>
          </header>
          <div className="timeline-item">
            <div className="timeline-marker is-icon">4</div>
            <div className="timeline-content">
              <p>Constitution de votre business plan</p>
            </div>
          </div>
          <div className="timeline-item">
            <div className="timeline-marker is-icon">5</div>
            <div className="timeline-content">
              <p>Constitution de votre dossier ICPE</p>
            </div>
          </div>
          <div className="timeline-item">
            <div className="timeline-marker is-icon">6</div>
            <div className="timeline-content">
              <p>Obtention du permis de construire</p>
            </div>
          </div>
          <header className="timeline-header">
            <span className="tag is-success">Projet sécurisé</span>
          </header>
          {
                /* <div class="timeline-header">
                 <span class="tag is-medium is-primary">End</span>
                </div> */
              }
        </div>
      </Column>
      <Column mdxType="Column">
        <Content size='medium' textAlign='left' mdxType="Content">
          <p>
            Nous mettons à profit notre cartographie des gisements pour accélérer l'étude de faisabilité, et vous
            accompagnons par la suite en équipe rapproché dans ces différentes étapes de développement.
          </p>
          <p>
            Enia peut être amenée à prendre des participations au capital de votre projet, gage de sérieux et
            d'implication sur le long terme qui fait notre renommée.
          </p>
        </Content>
      </Column>
    </Columns>
    <br />
    <a href='/contact'>
      <Button size='large' color='primary' mdxType="Button">
        👋 Je suis intéressé
      </Button>
    </a>
  </Container>
    </Section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      